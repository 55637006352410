@import "antd/dist/reset.css";
@import "react-quill/dist/quill.snow.css";

/* layout adjustments*/

.ant-layout-content {
  margin: 10px 20px;
}

#date-time-picker,
.ant-descriptions-view {
  margin-bottom: 30px;
}

/* notification */

#main .ant-notification a,
#main .ant-notification a:hover {
  color: #f28c8c;
}

/* typography */

#main p:last-child,
#main .ant-typography:last-child {
  margin-bottom: 0;
}

/* header */

#main .brain-logo {
  position: absolute;
  top: 0;
  left: -120px;

  width: 120px;
  height: 64px;

  object-position: center;
  object-fit: contain;
}

#main .ant-menu-item {
  font-weight: bold;
}

/* table */

#main .ant-table-thead .ant-table-cell {
  padding: 0;
}

#main .ant-table-column-sorters {
  padding-right: 16px;
}

/* zoom details */
#main .zoom-details button.ant-btn {
  font-size: 12px;
  margin-right: 7px;
  margin-bottom: 0.5em;
}

#main .zoom-details .ant-typography {
  font-size: 12px;
  margin-bottom: 0.5em;
}

/* modal */

#main .ant-modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

#main .ant-modal-header {
  margin-bottom: 15px;
}

/* form */

#main .error-border {
  border: 1px solid #ff4d4f;
}

#main .error-shadow {
  box-shadow: inset 0 0 0 2px #ff4d4f;
}

/* holidays */
#main .date-cell-highlight {
  background-color: pink;
}

#main .date-cell-highlight::after {
  display: none;
}

#main .row-highlight {
  background-color: pink;
}

#main .row-highlight .ant-table-cell-row-hover {
  background-color: pink;
  border-radius: 0;
}
